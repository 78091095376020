import { smoothly } from "smoothly"
import { proquse } from "@proquse/model"
import { State } from "@userwidgets/ui"
import { Client } from "../Client"

export class Currencies extends smoothly.StateBase<Currencies, Client> {
	private organization?: string
	public request?: Promise<Exclude<Currencies["value"], undefined>>
	#value?: Currencies["value"]
	get value(): proquse.Payment.Currencies | false | undefined {
		return this.#value ?? (this.fetch(), undefined)
	}
	set value(value: Currencies["value"]) {
		this.#value = value
		if (!this.#value)
			this.organization = undefined
	}
	private constructor(client: Client, private state: { userwidgets: State }) {
		super(client)
	}
	async fetch(): Promise<proquse.Payment.Currencies | false> {
		let result: proquse.Payment.Currencies | false
		if (this.request)
			result = await this.request
		else {
			const request =
				!this.state.userwidgets.me.key || !this.state.userwidgets.organizations.current
					? Promise.resolve(false as const)
					: this.client.currencies
							.list((this.organization = this.state.userwidgets.organizations.current.id))
							.then(result => (!proquse.Payment.Currencies.is(result) ? false : result))
			this.request = request
			result = await request
			if (this.request == request) {
				this.request = undefined
				this.#value !== result && (this.listenable.value = result)
			}
		}
		return result || false
	}
	private readonly subscriptions = {
		key: (key: State.Me["key"]) => {
			if (key !== undefined && this.#value !== undefined)
				this.fetch()
			else if (key === undefined && this.#value !== undefined)
				this.listenable.value = undefined
		},
		organization: (organization: State.Organizations["current"]) => {
			if (organization !== undefined && (organization || undefined)?.id !== this.organization)
				this.fetch()
			else if (organization === undefined && this.#value !== undefined)
				this.listenable.value = undefined
		},
	}
	static create(client: Client, userwidgets: State): smoothly.WithListenable<Currencies> {
		const backend = new this(client, { userwidgets })
		const listenable = smoothly.Listenable.load(backend)
		userwidgets.me.listen("key", key => backend.subscriptions.key(key), { lazy: true })
		userwidgets.organizations.listen("current", current => backend.subscriptions.organization(current), { lazy: true })
		return listenable
	}
}
