import { gracely } from "gracely"
import { userwidgets } from "@userwidgets/model"
import { http } from "cloudly-http"
import { rest } from "cloudly-rest"
import { CostCenter } from "./CostCenter"
import { Currencies } from "./Currencies"
import { Delegation } from "./Delegation"
import { Purchase } from "./Purchase"
import { Receipt } from "./Receipt"
import { Report } from "./Report"
import { Transaction } from "./Transaction"
import { Version } from "./Version"

const url = new URL(window.location.href)
const userwidgetsUrl = url.searchParams.get("userwidgets") ?? undefined
const proquseUrl =
	url.searchParams.get("backend") ??
	(url.host.search(/:\d{1,5}$/) != -1
		? // port specified -> local. change port to 8787
		  `${url.protocol}//${url.hostname}:8787`
		: // port not specified -> prod. change subdomain to api
		  `${url.protocol}//api.${url.host.split(".").slice(-2).join(".")}`)
const token = window.sessionStorage.getItem("token") ?? undefined
class Backend<T> extends http.Client<T> {
	protected async preProcess(request: http.Request): Promise<http.Request> {
		userwidgetsUrl && request.url.searchParams.set("userwidgetsOrigin", userwidgetsUrl)
		const result = {
			...request,
			header: {
				...(request.header.contentType != "multipart/form-data" && {
					contentType: request.body ? "application/json; charset=utf-8" : undefined,
				}),
				authorization: this.key ? `Bearer ${this.key}` : undefined,
				...(request.header.contentType == "multipart/form-data"
					? Object.fromEntries(
							Object.entries(request.header).filter(([header, value]) => {
								return header != "contentType" && value != "multipart/form-data"
							})
					  )
					: request.header),
			},
		}
		return result
	}
}

export class Client extends rest.Client<gracely.Error> {
	readonly userwidgets = new userwidgets.ClientCollection(this.client, {})
	readonly version = new Version(this.client)
	readonly delegation = new Delegation(this.client)
	readonly purchase = new Purchase(this.client)
	readonly receipt = new Receipt(this.client)
	readonly transaction = new Transaction(this.client)
	readonly costCenter = new CostCenter(this.client)
	readonly report = new Report(this.client)
	readonly currencies = new Currencies(this.client)

	constructor(client: http.Client) {
		super(client)
	}

	static create<T = Record<string, any>, Error = never>(
		url?: string,
		key?: string,
		load?: (connection: http.Client) => T
	): Client & T {
		const client = new Backend<Error>(url, key)

		const result = new this(client)
		if (load)
			Object.assign(result, load(client))
		return result as Client & T
	}
}

export const client = Client.create(proquseUrl, token)

http.Parser.add(
	async request =>
		(({ undefined: json, ...object }) => ({ ...object, ...json }))(await http.FormData.from(await request.formData())),
	"multipart/form-data"
)
